<template>
  <div id="onlyUse">
    <img
      src="@image/me/coupon_nodate.png"
      class="on_is"
      v-if="coupon.vouchertemplate_state > 1"
    />
    <div v-if="coupon.vouchertemplate_state > 1" class="on_is_fixed"></div>
    <div class="coupon_li">
      <!-- 全场的 -->
      <div class="all flex-container">
        <img src="@image/me/conpon_all.png" class="icon" />
        <div class="data flex1">
          <h1>
            <b v-if="!coupon.goods_info" style="font-weight: bold"
              >【全场通用券】</b
            >
            {{ coupon.vouchertemplate_title }}
          </h1>
          <div class="info">
            <i>￥</i>
            <b>{{ coupon.vouchertemplate_price }}</b>
            <span style="margin-left: 5px"
              >满{{ coupon.vouchertemplate_limit }}可用</span
            >
          </div>
        </div>
      </div>
    </div>

    <template>
      <div
        class="pay_title flex-container"
        v-if="coupon.goods_info && coupon.goods_info.length != 0"
      >
        <p>送你一张{{ coupon.vouchertemplate_storename }}专属优惠券</p>
        <p>(指定)以下商品均可使用</p>
      </div>

      <div class="pay_title flex-container" v-else>
        <p>送你一张{{ coupon.vouchertemplate_storename }}专属优惠券</p>

        <p>我们为你准备了纹绣师最近最爱购买的产品</p>
      </div>
    </template>

    <div class="product_list flex-container" v-if="goods && goods.length != 0">
      <div
        class="li"
        v-for="(item, index) in goods"
        @click="
          $router.push({
            name: 'HomeGoodsdetail',
            query: { goods_id: item.gid || item.goods_id },
          })
        "
        :key="index"
      >
        <img :src="item.image || item.goods_img_480" />
        <div class="info">
          <p>{{ item.gname || item.goods_name }}</p>
          <div class="flex-container">
            <b class="flex1"
              >￥{{ getPriceDot(item.gprice || item.goods_price, 0) }}.<i>{{
                getPriceDot(item.gprice || item.goods_price, 1)
              }}</i></b
            >
            <span v-if="item.goods_salenum"
              >售出:
              {{ item.goods_salenum + item.goods_vr_salenum || 0 }}笔</span
            >
          </div>
        </div>
      </div>
    </div>

    <div class="fixed_bar flex-container">
      <div class="li flex1" @click="$router.push({ name: 'HomeIndex' })">
        进入商城
      </div>
      <div class="li flex1 centre" @click="GetHappy" v-if="stutus">
        开心领下
      </div>
      <div
        class="li flex1 centre"
        v-else
        @click="$router.push({ name: 'HomeIndex' })"
      >
        去使用
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";

import { post, get } from "@get/http";
import { getReceiveVoucher, getShowVoucher, getProductList } from "./js/";
export default {
  data() {
    return {
      coupon: {},
      goods: [],
      stutus: true,
    };
  },
  methods: {
    getPriceDot(val, model = 0) {
      if (model == 0) {
        return val.toString().split(".")[model];
      } else {
        return val.toString().split(".")[model];
      }
    },
    async GetHappy() {
      if (!this.$route.query.v_id || this.coupon.is_show == 0) {
        Toast("十九匠商城：非法进入页面,禁止领取。");
        return false;
      }

      let code = await post(
        "/Gzmb/showCode",
        {
          data: { code: this.$route.query.code },
        },
        {
          result: true,
          resultKey: "key",
        }
      );

      if (code.result.status == 0) {
        let res = await getReceiveVoucher(this.$route.query.v_id);
        if (res.result == "兑换成功") {
          Toast("十九匠商城：领取成功");
          this.stutus = false;
        }
      } else {
        Toast("来晚了~优惠券已经被领走了！o(╥﹏╥)o");
      }
    },
    async hotProduct() {
      let { recently_products } = await getProductList();
      this.goods = recently_products;
    },
  },
  async created() {
    let result = await getShowVoucher(this.$route.query?.v_id);
    this.coupon = result;
    if (result.goods_info) {
      let yGoods = result.goods_info;
      for (const key in yGoods) {
        this.goods.push(yGoods[key]);
      }
    } else {
      this.hotProduct();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";

.pay_title {
  position: relative;
  z-index: 1;
  width: rem(700);
  height: rem(160);
  background: #fff;
  margin: 0 auto;
  border-radius: rem(20);
  flex-direction: column;
  justify-content: center;
  padding: 0 rem(40);
  box-sizing: border-box;
  box-shadow: 0px rem(3) rem(5) rgba(0, 0, 0, 0.1);
  p {
    &:first-of-type {
      color: #ef4f4f;
      font-size: rem(40);
    }
    &:last-of-type {
      color: #333333;
      font-size: rem(30);
    }
  }
}
.fixed_bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: rem(150);
  background: #ffffff;
  justify-content: center;
  align-items: center;
  box-shadow: 0px rem(-4) rem(20) 0px rgba(204, 204, 204, 0.15);
  .li {
    text-align: center;
    color: #343434;
    font-size: rem(28);
    font-weight: bold;
  }
  .centre {
    color: #ff4848;
  }
}

.product_list {
  // background: #fff;
  margin: 0 rem(24) rem(24);
  box-sizing: border-box;
  flex-wrap: wrap;
  justify-content: space-between;

  .li {
    width: rem(338);
    box-shadow: 0px rem(3) rem(6) rgba(0, 0, 0, 0.05);
    border-radius: rem(20);
    background: #fff;
    margin-top: rem(24);
  }

  img {
    width: rem(338);
    height: rem(338);
    display: block;
    border-radius: rem(20) rem(20) 0 0;
  }
  .info {
    padding: rem(20) rem(20) rem(26);
    box-sizing: border-box;
    p {
      color: #000000;
      font-size: rem(24);
      @include ell2;
      min-height: rem(68);
      // margin-bottom: rem(4);
    }
    div {
      align-items: center;

      b {
        color: #ef4f4f;
        font-size: rem(30);
        font-weight: bold;
        i {
          font-size: rem(20);
          font-weight: bold;
        }
      }
      span {
        color: #999999;
        font-size: rem(20);
      }
    }
  }
}
#onlyUse {
  padding: rem(20) 0 rem(150) 0;
}
.on_is {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.on_is_fixed {
  filter: blur(120px);
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 998;
}
.coupon_li {
  background: #fff;
  padding-bottom: rem(28);
  width: rem(700);
  border-radius: rem(20);
  margin: 0 auto rem(20);
  box-sizing: border-box;
  box-shadow: 0px rem(3) rem(5) rgba(0, 0, 0, 0.1);
  .all {
    .btn {
      p {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        margin: auto;
        text-align: center;
        font-size: rem(26);
        font-weight: bold;
        width: rem(140);

        line-height: rem(54);
        border-radius: rem(54/2);
        height: rem(54);
      }
      span {
        display: block;
        margin-top: rem(200) !important;
      }
      .p {
        color: #fff;

        background: linear-gradient(270deg, #ff5533 0%, #ff853e 100%);
        box-shadow: 1px rem(4) rem(8) 0px rgba(255, 72, 72, 0.2);
      }
      .p1 {
        color: #ff4848;
        border: 1px solid #ff4848;
      }
    }
    // justify-content: center;
    // align-items: center;

    .icon {
      display: block;
      width: rem(164);
      height: rem(164);
      border-radius: rem(8);
      padding-top: rem(38);
      padding-left: rem(38);
    }
    .data {
      position: relative;
      padding-top: rem(38);
      h1 {
        // width: 206px;
        font-size: rem(26);
        font-weight: bold;
        color: #343434;
        height: rem(70);
        margin-top: rem(8);
        margin-bottom: rem(10);
        padding: 0 rem(28);
        @include ell2;
      }
      .info {
        padding-left: rem(28);
        color: #ff4848;
        i {
          font-size: rem(30);
          font-weight: bold;
        }
        b {
          font-size: rem(64);
          font-weight: bold;
        }
        span {
          font-size: rem(20);
          color: #808080;
        }
      }
    }
  }

  .other {
    h1 {
      font-size: rem(26);
      font-weight: bold;
      color: #343434;
      width: rem(400);
      @include ell;
      margin-left: rem(38);
      height: rem(70);
      line-height: rem(70);
    }
    .product_ul {
      display: -webkit-box;
      overflow-x: auto;
      /*适应苹果*/
      -webkit-overflow-scrolling: touch;
      width: rem(490);
      .product_li {
        margin: 0 rem(10);

        &:first-of-type {
          margin-left: rem(38);
        }
        &:last-of-type {
          padding-right: rem(38);
        }
        img {
          display: block;
          width: rem(124);
          height: rem(124);
          border-radius: rem(4);
          margin-bottom: rem(4);
        }
        .info {
          color: #ff4848;
          align-items: center;
          margin-top: rem(10);
          i {
            font-size: rem(18);
            font-weight: bold;
          }
          b {
            font-size: rem(22);
            font-weight: bold;
          }
          span {
            margin-left: rem(6);
            font-size: rem(16);
            font-size: rem(16);
            color: #ffffff;
            display: inline-block;
            padding: 0 rem(6);
            height: rem(20);
            background: #ff4848;
            border-radius: rem(10) rem(10) rem(10) 0px;
          }
        }
      }
    }
    .btn {
      flex-direction: column;

      justify-content: center;
      .price {
        color: #ff4747;
        text-align: center;
        height: rem(60);
        margin-bottom: rem(10);
        i {
          font-size: rem(30);
          font-weight: bold;
        }
        b {
          font-size: rem(64);
          font-weight: bold;
        }
      }
      .tips {
        display: block;
        color: #808080;
        font-size: rem(20);
        text-align: center;
        margin-bottom: rem(9);
      }
      .time {
        text-align: center;
        margin-bottom: rem(14);
        span {
          color: #333333;
          font-size: rem(20);
        }
        i {
          display: inline-block;
          padding: 0 rem(4);
          margin: 0 rem(6);
          height: rem(30);
          line-height: rem(30);
          background: #343434;
          border-radius: rem(4);
          font-size: rem(20);
          font-weight: bold;
          color: #ffffff;
          line-height: rem(30);
        }
        b {
          font-size: rem(26);
          color: #343434;
        }
      }
      .btn_ {
        margin: 0 auto;
        width: rem(140);

        font-size: rem(26);
        font-weight: bold;
        text-align: center;
        line-height: rem(54);
        height: rem(54);
        border-radius: rem(54/2);
      }
      .no {
        background: linear-gradient(270deg, #ff5533 0%, #ff853e 100%);
        box-shadow: 1px rem(4) rem(8) 0px rgba(255, 72, 72, 0.2);
        color: #fff;
      }
      .yes {
        color: #ff4848;
        border: 1px solid #ff4848;
      }
    }
  }
}
</style>
